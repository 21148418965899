.SupportPage {
    min-height: calc(100svh - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 100px;

    .left-side {
        width: 500px;
        gap: 40px;

        display: flex;
        text-align: center;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        margin-right: auto;
        margin-left: auto;

        @media screen and (max-width: 600px) {
            & {
                width: auto;
                padding-left: 16px;
                padding-right: 16px;
            }
        }
    }

    .form-container {
        margin-right: auto;
    }

    .Form {
        box-shadow: 0px 25px 60px -10px rgba(28, 39, 49, 0.12);
        width: 600px;

        .form-content {
            padding: 0 80px 40px 80px;
        }

        .mt-50 {
            margin-top: 50px;
        }

        @media screen and (max-width: 550px) {
            & {
                width: auto;
                margin-right: 20px;
            }
        }

        @media screen and (max-width: 450px) {
            & {
                width: auto;
                margin-right: 20px;
            }
        }
    }

    @media screen and (max-width: 550px) {
        padding: 200px 0;
        .form-container {
            width: 100%;
            margin: 0 20px;
            .Form {
                .form-content {
                    padding: 0 20px 40px 20px;
                }
            }
        }
    }

    @media screen and (max-width: 1200px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;
        height: 100% !important;
        padding: 75px 0;

        .form-container {
            margin-right: 0;
        }
    }
}
