.main {
    * {
        box-sizing: border-box;
    }
}

.layout {
    padding: 120px 16px;
    margin: 0 auto;
    max-width: calc(1280px + 32px);
    display: grid;
    gap: 40px;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;

    @media (max-width: 768px) {
        padding: 40px 16px;
        gap: 24px;
    }
}

.top {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 516px;
    gap: 40px;

    @media (max-width: 768px) {
        grid-template-rows: 440px;
        gap: 24px;
    }
}

.bottom {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 40px;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 24px;
    }

    div {
        border: 1px solid green;
    }
}

.link {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 20px;
    border: 1px solid #707070;
    background: #f9f9f9;
    padding: 16px;
    text-align: center;
    color: #2b2b2b;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;

    &:hover {
        background-color: #f6f4f4;
        cursor: pointer;
    }
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100svh - 80px);
}

.featureCard {
    position: relative;
    display: grid;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-radius: 50px;
    overflow: hidden;

    &:hover {
        .backgroundImage {
            opacity: 0;
        }
    }

    a {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    .backgroundImage {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 1;
        width: 100%;
        height: 100%;
        background-image: url('../../Assets/Img/PartnerPortal/internal-testing-card-thumbnail.jpg');
        background-size: cover;
        transition: all 0.3s ease-in-out;
        background-position: center;
    }

    .videoContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 50px;
        border: 1px solid #707070;
        height: 100%;

        background: linear-gradient(
                262deg,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0.17) 29.28%,
                rgba(0, 0, 0, 0.38) 54.98%,
                rgba(0, 0, 0, 0.8) 90.85%
            ),
            linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0.21) 32.23%,
                rgba(0, 0, 0, 0.48) 60.52%,
                #000 100%
            ),
            radial-gradient(
                48.02% 48.02% at 50% 51.98%,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0.06) 28.79%,
                rgba(0, 0, 0, 0.14) 69.83%,
                rgba(0, 0, 0, 0.19) 100%
            );
    }

    .featureInfo {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        width: 100%;
        height: 100%;
        padding: 68px 48px;
        color: #ffffff;
    }

    .featureDescription {
        width: min(100%, 427px);

        p:first-of-type {
            font-weight: 700;
        }

        p:nth-last-of-type(2) {
            padding-bottom: 14px;
        }
    }
}

.tab {
    border-radius: 50px;
    border: 1px solid #fff;
    background: rgba(0, 0, 0, 0.35);
    padding: 12px 24px;
    backdrop-filter: blur(15px);

    h4 {
        color: #fff;
        text-align: center;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
    }
}
