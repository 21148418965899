.videoContainer {
    position: relative;
    border-radius: 30px;
    overflow: hidden;

    &:hover {
        .mute {
            opacity: 1;
        }
    }

    video {
        height: auto;
        width: 100%;
        border-radius: 30px;
    }

    .mute {
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 16px;
        left: 16px;
        background-color: rgba($color: #000000, $alpha: 0.7);
        opacity: 0;
        height: 48px;
        width: 48px;
        border-radius: 100%;
        z-index: 20;
        transition: all 0.3s ease;

        &:hover {
            cursor: pointer;
        }
    }
}
