.card {
    position: relative;
    display: flex;
    width: 403px;
    height: 420px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    flex-shrink: 0;
    margin-right: 12px;
    margin-left: 20px;
    margin-top: 24px;
    margin-bottom: 24px;
    box-shadow: none;
    transition: background-color 0.5s ease;

    &:hover {
        cursor: pointer;

        .background {
            transform: scale3d(1.01, 1.02, 1);
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
        }
    }

    @media (max-width: 1320px) {
        margin-left: 16px;
    }

    @media (max-width: 750px) {
        gap: 8px;
        width: 326px;
    }

    .icon {
        display: flex;
        flex-shrink: 0;
        width: 80px;
        height: 80px;
        padding: 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 20px;
        background: #f9f9f9;
        transition: all 0.5s ease;

        .outlinedIcon {
            opacity: 1;
            transition: all 0.5s ease;
        }

        .filledIcon {
            opacity: 0;
            transition: all 0.5s ease;
        }

        div {
            flex-shrink: 0;
            position: absolute;
        }
    }

    .content {
        transition: all 0.5s ease;

        h4 {
            color: #2b2b2b;
            font-family: 'poppins', sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 8px;
            transition: color 0.5s ease;
        }

        p {
            font-size: 18px;
            font-weight: 400;
            line-height: 150%;
            color: #2b2b2b;
            transition: color 0.5s ease;

            @media (max-width: 500px) {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    inset: 0;
    border: 1px solid #707070;
    background: #ffffff;
    box-sizing: border-box;
    z-index: -1;
    border-radius: 20px;
    transition: all 0.5s ease;
}

.active {
    .background {
        background-color: #2b2b2b;
        border-color: hsl(0, 0%, 17%);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    }

    .icon {
        background: #ffffff;

        .outlinedIcon {
            opacity: 0;
        }

        .filledIcon {
            opacity: 1;
        }
    }

    .content {
        h4 {
            color: #ffffff;
        }

        p {
            color: #ffffff;
        }
    }
}
